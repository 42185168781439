import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { SupportedLanguages } from '../types';

export const useLanguage = () => {
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    async (lang: SupportedLanguages) => {
      try {
        await i18n.changeLanguage(lang);
        // Optionally persist the language choice
        // localStorage.setItem('i18nextLng', lang);
        return true;
      } catch (error) {
        console.error('Failed to change language:', error);
        return false;
      }
    },
    [i18n]
  );

  return {
    currentLanguage: i18n.language as SupportedLanguages,
    changeLanguage,
    languages: {
      en: 'English',
      'fr-CA': 'Français (CA)',
    } as const,
  };
};
