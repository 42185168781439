import { useFeatureFlagEnabled } from 'posthog-js/react';

interface FeatureFlagWrapperProps {
  flagName: string;
  force?: boolean;
  featureOn: React.ReactNode;
  featureOff: React.ReactNode;
}

const FeatureFlagWrapper = ({
  flagName,
  force = false,
  featureOn,
  featureOff,
}: FeatureFlagWrapperProps) => {
  // If force is true, we can return featureOn immediately (only for development)
  if (force && (
    import.meta.env.MODE === 'development' ||
    window.location.hostname.includes("localhost")
  )) {
    return featureOn;
  }

  const isEnabled = useFeatureFlagEnabled(flagName);

  if (isEnabled === undefined) return null;
  return isEnabled ? featureOn : featureOff;
};

export default FeatureFlagWrapper;