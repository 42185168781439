import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from '../..';
import { baseApi } from '../baseApi';

export interface NotLoggedEntriesResponse {
  status: boolean;
  data: LoggedTimeEntry[];
  message?: string;
}
export interface StoreTimeEntryResponse {
  status: boolean;
  data: LoggedTimeEntry;
  message?: string;
}

export interface LoggedTimeEntry {
  id: number;
  user_id: string;
  date: string;
  site_id: string;
  task_id: string;
  spent_time: string;
  start_time: string;
  end_time: string;
  billable: number;
  description: any;
  logged: number;
  task_name: string;
  site_name: string;
  encrypted_task_id: string;
  running: number;
  started_at: string;
}
export interface StoreTimeEntryRequest {
  site_id: string;
  task_id: string;
  user_id: string;
  date: string;
  start_time: string;
  spent_time: number;
  running: boolean;
}

export interface UpdateTimeEntryRequest {
  task_id: string;
  id: number;
  user_id: string;
  start_time: string;
  end_time?: string | null;
  spent_time: string | number;
  logged: boolean;
  description?: string | null;
  billable: number;
  running: boolean;
  started_at_spent_time: boolean;
  started_at?: boolean;
}

export interface UpdateLogTimeEntryRequest {
  task_id: string | null;
  id: number | null;
  user_id: string;
  start_time: string | any;
  end_time?: string | null;
  spent_time: string | number;
  logged: boolean;
  description?: string | null;
  billable: number;
  complete: boolean | string;
}

export interface TimeEntryData {
  id: number;
  user_id: string;
  site_id: string;
  task_id: string;
  spent_time: string;
  start_time: string;
  end_time: string;
  billable: number;
  description: string | null;
  logged: number;
  running: number;
  started_at_spent_time: boolean | null;
  started_at: string;
  created_at: string;
  updated_at: string;
  clickup_task_id: string | null;
  teamwork_timer_id: string | null;
  user_name: string;
  avatar: string | null;
  email: string;
  task_title: string;
  name: string;
  project_id: string | null;
}

interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
}

interface UserFilterOption extends FilterOption {
  avatar: string | null;
  email: string;
}

interface FilterData {
  sites: FilterOption[];
  date: FilterOption[];
  users: UserFilterOption[];
  billable: FilterOption[];
  projects: any[];
}

interface TimeEntriesResponse {
  status: boolean;
  data: {
    [date: string]: TimeEntryData[];
  };
  filter_data: FilterData;
  more_web_records: boolean;
}

export const snippetsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // Queries
    getTimeEntries: build.query<TimeEntriesResponse['data'], void>({
      queryFn: async (
        _,
        api,
        __,
        baseQuery
      ): Promise<
        { data: TimeEntriesResponse['data'] } | { error: FetchBaseQueryError }
      > => {
        const workspaceId = (api.getState() as RootState).workspace
          .selectedWorkspace.id;
        const url = `/time-entries?offset=0&limit=25&screen=&workspace_id=${workspaceId}`;
        const result = await baseQuery(url);

        if (result.error) {
          return { error: result.error };
        }

        const response = result.data as TimeEntriesResponse;
        return { data: response.data };
      },
      providesTags: (_, error) => {
        if (error) return [];
        return [{ type: 'TimeEntry', id: 'LIST' }];
      },
    }),
    getNotLoggedEntries: build.query<LoggedTimeEntry[], string>({
      query: (workspaceId) => ({
        url: `/time-entries/not-logged?workspace_id=${workspaceId}`,
        method: 'GET',
      }),
      transformResponse: (response: NotLoggedEntriesResponse) =>
        response.data as LoggedTimeEntry[],
      providesTags: (_, error) => {
        if (error) return [];
        return [{ type: 'NotLoggedEntry', id: 'LIST' }];
      },
    }),
    // Mutations
    storeTimeEntry: build.mutation<LoggedTimeEntry, StoreTimeEntryRequest>({
      query: (timeEntryData) => ({
        url: '/time-entries/store',
        method: 'POST',
        body: timeEntryData,
      }),
      transformResponse: (response: StoreTimeEntryResponse) => response.data,
      invalidatesTags: (_, error) => {
        if (error) return [];
        return [
          { type: 'TimeEntry', id: 'LIST' },
          { type: 'NotLoggedEntry', id: 'LIST' },
        ];
      },
    }),
    updateTimeEntry: build.mutation<any, UpdateTimeEntryRequest>({
      query: ({ task_id, ...body }) => ({
        url: '/time-entries/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error) => {
        if (error) return [];
        return [
          { type: 'TimeEntry', id: 'LIST' },
          { type: 'NotLoggedEntry', id: 'LIST' },
        ];
      },
    }),
    playTimeEntry: build.mutation<any, UpdateTimeEntryRequest>({
      query: ({ task_id, ...body }) => ({
        url: `/time-entries/${body.id}/play`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error) => {
        if (error) return [];
        return [
          { type: 'TimeEntry', id: 'LIST' },
          { type: 'NotLoggedEntry', id: 'LIST' },
        ];
      },
    }),
    updateLogTimeEntry: build.mutation<any, UpdateLogTimeEntryRequest>({
      query: ({ task_id, ...body }) => ({
        url: '/time-entries/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error) => {
        if (error) return [];
        return [
          { type: 'TimeEntry', id: 'LIST' },
          { type: 'NotLoggedEntry', id: 'LIST' },
        ];
      },
    }),
    deleteTimeEntry: build.mutation<any, string>({
      query: (timeId) => ({
        url: `/time-entries/destroy/${timeId}`,
        method: 'GET',
      }),
      invalidatesTags: (_, error) => {
        if (error) return [];
        return [
          { type: 'TimeEntry', id: 'LIST' },
          { type: 'NotLoggedEntry', id: 'LIST' },
        ];
      },
    }),
  }),
});

export const {
  //queries
  useGetTimeEntriesQuery,
  useGetNotLoggedEntriesQuery,
  //mutations
  useStoreTimeEntryMutation,
  useUpdateTimeEntryMutation,
  usePlayTimeEntryMutation,
  useUpdateLogTimeEntryMutation,
  useDeleteTimeEntryMutation,
} = snippetsApi;
