import { useDispatch, useSelector } from 'react-redux';
// Styling
import { makeStyles } from '@material-ui/core';
import { ArrowBigUpDash } from 'lucide-react';
import qs from 'qs';
// Actions
import { HANDLE_PLANS_DIALOG } from 'actions/user';
import { Button, ResponsiveDialog } from 'ui/components';

const makeClasses = makeStyles((theme) => ({
  upgradeImgShadow: {
    width: '50%',
    height: '15%',
    display: 'block',
    left: '25%',
    top: '45%',
    position: 'absolute',
    boxShadow: '0px 0px 119px 50px #292170',
    zIndex: '-1',
    borderRadius: '104px',
  },
}));

const LockFeatureModal = (props) => {
  const {
    openLockFeatureModal,
    lockTitle,
    lockParagraph,
    lockImg,
    handleCloseLock,
    upSellUrl,
    featureString,
    closeOnAction = false,
  } = props;

  const { userData } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const classes = makeClasses();

  let url = qs.parse(upSellUrl, { ignoreQueryPrefix: true }).users;

  const featureUrl = url ? `${upSellUrl}&feature=${featureString}` : upSellUrl;

  const unlockClick = (path) => {
    if (userData?.plan?.from_free_plan) {
      dispatch({
        type: HANDLE_PLANS_DIALOG,
        params: true,
      });
      if (closeOnAction) {
        handleCloseLock();
      }
    } else {
      window.open(path);
    }
  };

  return (
    <>
      <ResponsiveDialog
        open={openLockFeatureModal}
        modal={true}
        onOpenChange={handleCloseLock}
        className="isolate"
      >
        <ResponsiveDialog.Content
          className="text-secondary isolate m-auto my-0 max-w-[750px] border-none p-0"
          showClose={true}
          onInteractOutside={(event) => event.preventDefault()}
        >
          <ResponsiveDialog.Title className="sr-only">
            Let's Unlock This Feature
          </ResponsiveDialog.Title>
          <div className="border-b p-5 text-lg font-semibold">
            Let's Unlock This Feature
          </div>
          <div className="flex flex-col p-3.5 md:flex-row">
            <div className="relative p-2.5">
              <div className="absolute left-[25%] top-[45%] z-[-1] block h-[15%] w-[50%] rounded-[104px] shadow-[0_0_119px_50px_#292170]"></div>
              <img
                className="-mx-2.5 block w-full md:min-w-[480px]"
                src={lockImg}
                alt="img"
              />
            </div>
            <div className="flex flex-col gap-4 rounded-md border px-3.5 py-5">
              <div className="text-base font-bold leading-none">
                {lockTitle}
              </div>
              <p className="text-sm">{lockParagraph}</p>
              <Button
                size="sm"
                leftIcon={<ArrowBigUpDash size={18} />}
                onClick={() => unlockClick(featureUrl)}
              >
                Upgrade To Unlock
              </Button>
            </div>
          </div>
        </ResponsiveDialog.Content>
      </ResponsiveDialog>
    </>
  );
};

export default LockFeatureModal;
