import { GoogleOAuthProvider } from '@react-oauth/google';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import App from './App';

import { Provider } from 'react-redux';
import { initializeI18n } from 'translations';
import store from './store/index';

initializeI18n({
  projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
  isProduction: import.meta.env.PROD,
});

function Main() {
  return (
    <Provider store={store}>
      <PostHogProvider client={posthog}>
        <GoogleOAuthProvider
          clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}
        >
          <App />
        </GoogleOAuthProvider>
      </PostHogProvider>
    </Provider>
  );
}

export default Main;
