import { FetchBaseQueryError, TagDescription } from '@reduxjs/toolkit/query';
import { CommentType } from '@src/store/types/comment';
import { CONSTANT_TASK_LISTING_PAGE_LIMIT } from 'utils/constants';
import { RootState } from '../..';
import {
  AllTaskResponse,
  CommentFile,
  NotifyWpFeedbackUser,
  ProjectResponse,
  TaskAttributes,
  TaskAttributesErrorResponse,
  TaskPriority,
  TaskResponse,
  TaskStatus,
} from '../../types/task';
import { baseApi } from '../baseApi';

export type TaskType = 'Page' | 'Email' | 'General' | 'Graphics';

export interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  site_id?: string;
  inbox_id?: string;
}

export interface FilterResponseData {
  task_status: FilterOption[];
  task_types: FilterOption[];
  task_urgency: FilterOption[];
  task_notify_users?: NotifyWpFeedbackUser[]; // Assuming this can be an array of any type
  notify_wp_feedback_users?: FilterOption[];
  task_tags?: FilterOption[];
  responsible_user?: FilterOption[];
  author_name?: FilterOption[];
}

export interface SiteFilterResponse {
  status: boolean;
  data: FilterResponseData;
}
export interface CreateTaskRequest {
  responsible_user_id?: number;
  tag_values: string[];
  tag_ids: string[];
  site_id: string;
  task_page_url?: string;
  current_page_id?: string | number;
  task_type: TaskType;
  task_title: string;
  task_page_title: string;
  task_comment_message: string;
  task_priority: TaskPriority;
  task_status: TaskStatus;
  task_notify_users: string;
  task_config_author_name: string;
  task_config_author_browser: string;
  task_config_author_browserVersion: string;
  task_config_author_id: string;
  task_config_author_resX?: number;
  task_config_author_resY?: number;
  task_element_path?: string;
  internal: 1 | 0;
  avatar?: string | null;
  is_note: 1 | 0;
  guest_user_id?: string;
  task_comment_id?: number;
  scroll_to?: number;
  viewport_width?: number;
  viewport_height?: number;
  files?: any[];
  comments: Array<{
    comments: string;
    is_deleted: boolean;
    is_edited: boolean;
    user_id: string;
    user_name: string;
    is_note: number;
    days?: string;
    comment_type: CommentType;
    is_guest: boolean;
    files?: any[];
  }>;
}

interface UpdateCommentContentRequest {
  task_id: string;
  comment_id: string;
  comment_content: string;
  comment_type: string;
}

interface CreateTaskResponse {
  status: number;
  message: string;
  result: TaskResult;
}

interface TaskResult {
  id: number;
  task_id: string;
  page_approved_at: string | null;
  task_comment_id: number;
  latest_comment: LatestComment;
  task_title: string;
  task_page_title: string;
  task_comment_message: string;
  task_created_by: string;
  task_created_time: string;
  task_created_time_ago: string;
  task_type: any[];
  is_complete: boolean;
  is_internal: boolean;
  is_unread: boolean;
  task_status: string;
  task_priority: string;
  page_url: string;
  task_page_url: string;
  task_elementX: number;
  task_elementY: number;
  task_element_path: string;
  graphic_id: string | null;
  task_tags: any[];
  is_approved: number;
  atarim_task_screenshot: string;
  is_pinned: boolean;
  wpf_task_screenshot: string;
  task_position: any[];
  is_guest: boolean;
  notify_wp_feedback_users: NotifyUser[];
  collaborators: Collaborator[];
  width: number;
  height: number;
}

interface LatestComment {
  id: string;
  comments: string;
  comment_type: string;
  author: Author;
  days: string;
}

interface Author {
  id: string;
  user_name: string;
  avatar: string | null;
  email: string;
}

interface NotifyUser {
  label: string;
  value: number;
  checked: boolean;
  avatar: string | null;
  email: string;
  responsible: boolean;
}

interface Collaborator {
  user_id: string;
  normal_id: number;
  name: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  add_to_website_default: number;
  assign_to_task_default: number;
  avatar: string | null;
  role: string;
  checked: number;
  notificationPreference: NotificationPreference;
  site_user_role: string | null;
}

interface NotificationPreference {
  id: number;
  user_id: number;
  every_new_task: number;
  every_new_comment: number;
  every_status_change: number;
  daily_report: number;
  weekly_report: number;
  created_at: string;
  updated_at: string;
  wpf_user_id: string | null;
  wpf_every_new_complete: number;
  wpf_auto_daily_report: number;
  wpf_auto_weekly_report: number;
}

export type FetchSiteListParams = {
  offset?: number;
  limit?: number;
  search_keyword?: string;
  highlight_project_id?: string;
  userId?: string;
  filter_by?: string;
  order_by?: FetchSiteOrderBy;
  workspace_id?: number;
  apply_filter?: boolean;
  highlight_site_id?: string;
};

export type FetchSiteOrderBy =
  | 'sites.created_at'
  | 'sites.name'
  | 'projects.id'
  | 'sites.updated_at'
  | 'tasks.id'
  | 'tasks_count';

export type FetchTaskListParams = {
  offset?: number;
  limit?: number;
  search_keyword?: string;
  userId?: string;
  filter_by?: string;
  workspace_id?: number;
  type?: string;
  apply_filter?: boolean;
  order_by?: string;
  is_mailbox?: boolean;
  column_type?: string;
  task_category?: string;
  task_status?: string[];
  task_urgency?: string[];
  task_types?: string[];
  task_tags?: string[];
  responsible_user?: string[];
  highlight_task_id?: string;
  author_name?: string[];
};

type BulkMethods =
  | 'status'
  | 'priority'
  | 'notify_wpfeedback_users'
  | 'task_notify_users'
  | 'notify_users'
  | 'task_title'
  | 'task_tags'
  | 'delete_tags'
  | 'reconnect_task'
  | 'pin'
  | 'unpin';

// the union is because taskId's / websiteId's are required
export type BulkUpdateParams = {
  taskId?: string;
  taskIds?: string[];
  websiteId?: string;
  websiteIds?: string[] | Set<string>;
  value?: any;
  updateMethod?: BulkMethods;
  internal?: number;
  is_delete?: boolean;
  id?: string | number;
} & ({ taskId: string } | { taskIds: string[] }) &
  ({ websiteId: string } | { websiteIds: string[] | Set<string> });

export type UpdateStatus = {
  taskId: string;
  websiteId?: string;
  value: TaskStatus;
};

export type BulkUpdateStatus = {
  taskIds: string[];
  websiteIds: string[];
  value: TaskStatus;
};

export type UpdatePriority = {
  taskId: string;
  websiteId?: string;
  value: TaskPriority;
};

export type BulkUpdatePriority = {
  taskIds: string[];
  websiteIds: string[];
  value: TaskPriority;
};

interface AttributesApiResponse<T> {
  result: T;
  status: number;
}

interface TaskAttachmentResponse {
  status: number;
  message: string;
  result: TaskAttachmentData;
}

export interface TaskAttachmentData {
  norm_id: number;
  id: string;
  comments: string;
  is_deleted: boolean;
  is_edited: boolean;
  updated_at: string;
  user_id: string;
  user_normal_id: string;
  user_name: string;
  is_note: number;
  days: string;
  comment_date: string;
  comment_type: string;
  login_user_name: string;
  email: string;
  avatar: any;
  value: string;
  task_id: string;
  file_name: string;
  is_guest: boolean;
  files: CommentFile[];
}

// Define the request type
export interface TaskAttachmentRequest {
  task_id: string;
  comment_content: string;
  comment_type: CommentType;
  complete_task: boolean;
  user_id: string;
  guest_author_name: string;
  is_note: number;
  guest_user_id: string;
  files: FileList | File[];
}

export interface FetchPagesResponse {
  status: boolean;
  data: FetchPagesData;
}

export interface FetchPagesData {
  task_urgency: FetchPagesTaskUrgency[];
  task_status: FetchPagesStatus[];
  pages: FetchPagesPages | [];
  wp_pages: any[];
  notify_wp_feedback_users: FetchPagesNotifyWpFeedbackUser[];
}

export interface FetchPagesTaskUrgency {
  label: string;
  value: string;
  selected: boolean;
}

export interface FetchPagesStatus {
  label: string;
  value: string;
  selected: boolean;
}

export interface FetchPagesPages {
  page: FetchPagesPage[];
}

export interface FetchPagesPage {
  value: number;
  id: string;
  label: string;
  domain: string;
  slug: string;
  url: string;
  target_url: string;
  iframe_url: string;
  wp_page_id: any;
  is_approved: boolean;
  total_tasks: number;
  screenshot: string;
}

export interface FetchPagesNotifyWpFeedbackUser {
  label: string;
  value: number;
  checked: boolean;
}

interface TaskStoreParams {
  site_id: string;
  body: TaskStoreRequest;
}

interface TaskStoreRequest {
  users: any[];
  urgency: TaskPriority;
  status: TaskStatus;
  page: number;
  message: string;
  screenWidth: number;
  screenHeight: number;
  browserName: string;
  browserVersion: string;
  responsible_user: number;
}

interface TaskStoreResponse {
  status: boolean;
  data: {
    result: string;
  };
}

interface TimeEntryData {
  site_id: string;
  task_id: string;
  user_id: string;
  date: string;
  start_time: string;
  spent_time: number;
  running: boolean;
}

export const taskApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchSiteList: build.query<ProjectResponse, Partial<FetchSiteListParams>>({
      queryFn: async (params = {}, api, _, baseQuery) => {
        const searchParams = new URLSearchParams();
        const defaultParams: FetchSiteListParams = {
          offset: 0,
          limit: 20,
          workspace_id: (api.getState() as RootState).workspace
            .selectedWorkspace.id,
          ...params,
        };

        Object.entries(defaultParams).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) =>
              searchParams.append(`${key}[]`, item.toString())
            );
          } else {
            if (value !== undefined && value !== null) {
              searchParams.append(key, value.toString());
            }
          }
        });

        const url = `/site?${searchParams.toString()}`;
        const result = await baseQuery(url);

        if (result.error) {
          return { error: result.error };
        }

        return { data: result.data as ProjectResponse };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { offset, ...rest } = queryArgs;
        return rest;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (!currentCache) return newItems;

        let mergedData;
        if (arg.offset === 0) {
          // If it's the first page, just use the new items
          mergedData = newItems.data;
        } else {
          // Create a Set of task IDs from the current cache for quick lookup
          const existingSiteIds = new Set(
            currentCache.data.map((site) => site.id)
          );

          // Filter out duplicates from the new items
          const uniqueNewItems = newItems.data.filter(
            (site) => !existingSiteIds.has(site.id)
          );

          // Merge the current cache with unique new items
          mergedData = [...currentCache.data, ...uniqueNewItems];
        }

        return {
          ...currentCache,
          ...newItems,
          data: mergedData,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        if (currentArg && previousArg) {
          return currentArg.offset !== previousArg.offset;
        }
        return true;
      },
      providesTags: [{ type: 'Site', id: 'LIST' }],
    }),
    fetchAllTasks: build.query<AllTaskResponse, FetchTaskListParams>({
      queryFn: async (params = {}, api, _, baseQuery) => {
        const searchParams = new URLSearchParams();
        const defaultParams: FetchTaskListParams = {
          offset: 0,
          limit: CONSTANT_TASK_LISTING_PAGE_LIMIT,
          type: 'task',
          workspace_id: (api.getState() as RootState).workspace
            .selectedWorkspace.id,
          ...params,
        };

        Object.entries(defaultParams).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) =>
              searchParams.append(`${key}[]`, item.toString())
            );
          } else if (key === 'order_by' && typeof value === 'string') {
            if (!value.includes(':')) throw new Error('Invalid order_by value');
            let [sortField, sortOrder = 'desc'] = value.split(':');
            searchParams.append('order_by', sortField);
            searchParams.append('direction', sortOrder);
          } else {
            if (value !== undefined && value !== null) {
              searchParams.append(key, value.toString());
            }
          }
        });

        const url = `/task/get-all-tasks?${searchParams.toString()}`;
        const result = await baseQuery(url);

        if (result.error) {
          return { error: result.error };
        }

        return { data: result.data as AllTaskResponse };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { offset, ...rest } = queryArgs;
        return rest;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (!currentCache) return newItems;

        let mergedData;
        if (arg.offset === 0) {
          // If it's the first page, just use the new items
          mergedData = newItems.data;
        } else {
          // Create a Set of task IDs from the current cache for quick lookup
          const existingTaskIds = new Set(
            currentCache.data.map((task) => task.task_id)
          );

          // Filter out duplicates from the new items
          const uniqueNewItems = newItems.data.filter(
            (task) => !existingTaskIds.has(task.task_id)
          );

          // Merge the current cache with unique new items
          mergedData = [...currentCache.data, ...uniqueNewItems];
        }

        return {
          ...currentCache,
          ...newItems,
          data: mergedData,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        if (currentArg && previousArg) {
          return currentArg.offset !== previousArg.offset;
        }
        return true;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ task_id }) => ({
                type: 'Task' as const,
                id: task_id,
              })),
              { type: 'Task', id: 'LIST' },
            ]
          : [{ type: 'Task', id: 'LIST' }],
    }),
    fetchTaskList: build.query<
      TaskResponse,
      {
        id: string;
        query?: Partial<FetchTaskListParams>;
        limit?: number;
        offset?: number;
      }
    >({
      query: (
        params = {
          id: '',
          query: {},
          limit: CONSTANT_TASK_LISTING_PAGE_LIMIT,
          offset: 0,
        }
      ) => {
        const searchParams = new URLSearchParams();
        const { id, query, limit, offset } = params;
        const defaultParams: FetchTaskListParams = {
          offset,
          limit,
          apply_filter: true,
          ...query,
        };
        Object.entries(defaultParams).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) =>
              searchParams.append(`${key}[]`, item.toString())
            );
          } else if (key === 'order_by' && typeof value === 'string') {
            if (!value.includes(':')) throw new Error('Invalid order_by value');
            let [sortField, sortOrder = 'desc'] = value.split(':');
            searchParams.append('order_by', sortField);
            searchParams.append('direction', sortOrder);
          } else {
            if (value !== undefined && value !== null) {
              searchParams.append(key, value.toString());
            }
          }
        });

        return `/site/tasks/${id}?${searchParams.toString()}`;
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { offset, ...rest } = queryArgs;
        return rest;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (!currentCache) return newItems;

        let mergedData;
        if (arg.offset === 0) {
          // If it's the first page, just use the new items
          mergedData = newItems.data;
        } else {
          // Create a Set of task IDs from the current cache for quick lookup
          const existingTaskIds = new Set(
            currentCache.data.map((task) => task.task_id)
          );

          // Filter out duplicates from the new items
          const uniqueNewItems = newItems.data.filter(
            (task) => !existingTaskIds.has(task.task_id)
          );

          // Merge the current cache with unique new items
          mergedData = [...currentCache.data, ...uniqueNewItems];
        }

        return {
          ...currentCache,
          ...newItems,
          data: mergedData,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        if (currentArg && previousArg) {
          return currentArg.offset !== previousArg.offset;
        }
        return true;
      },
      providesTags: (_, error, arg) => {
        if (error) return [];
        return [{ type: 'Task', id: arg.id }];
      },
    }),
    fetchTaskAttributes: build.query<TaskAttributes, string>({
      queryFn: async (id, api, _, baseQuery) => {
        const workspaceId = (api.getState() as RootState).workspace
          .selectedWorkspace.id;

        const result = await baseQuery({
          url: `/collaborate/tasks/${id}`,
          method: 'GET',
          params: { workspace_id: workspaceId },
        });

        if (result.error) {
          // Check if the error is from the API
          const apiError = result.error.data as TaskAttributesErrorResponse;
          if (apiError) {
            return {
              error: {
                status: 'CUSTOM_ERROR',
                error: apiError.message,
                data: {
                  status: apiError.status,
                  descriptor: apiError.descriptor,
                },
              } as FetchBaseQueryError,
            };
          }
          // If it's not an API error, return the original error
          return { error: result.error };
        }

        const response = result.data as AttributesApiResponse<TaskAttributes>;
        return { data: response.result };
      },
      providesTags: (_, error, id) => {
        if (error) return [];
        return [{ type: 'Attribute', id }];
      },
    }),
    fetchAllFilterData: build.query<FilterResponseData, string>({
      query: (workspaceId: string) =>
        `/site/get-filter-data?workspace_id=${workspaceId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: (_, error) => {
        if (error) return [];
        return [{ type: 'Filter', id: 'LIST' }];
      },
    }),
    fetchSiteFilterData: build.query<FilterResponseData, string>({
      query: (site_id: string) => `/site/get-filter-data/${site_id}`,
      transformResponse: (response: { data: FilterResponseData }) =>
        response.data,
      providesTags: (_, error, site_id) => {
        if (error) return [];
        return [{ type: 'Filter', id: site_id }];
      },
    }),
    fetchTaskPages: build.query<FetchPagesData, string>({
      query: (id: string) => `/site/pages/${id}`,
      transformResponse: (response: FetchPagesResponse) => response.data,
      providesTags: (_, error, id) => {
        if (error) return [];
        return [{ type: 'Page', id }];
      },
    }),

    // Mutations
    bulkUpdateTaskDetails: build.mutation<any, BulkUpdateParams>({
      query: ({
        taskId,
        taskIds,
        value,
        updateMethod,
        internal,
        is_delete,
        id,
      }) => {
        return {
          url: '/task/bulkupdate-task-details',
          method: 'POST',
          body: {
            method: updateMethod,
            task_id: taskId,
            task_ids: taskIds,
            value,
            internal,
            is_delete,
            id,
          },
        };
      },
      invalidatesTags: (
        _,
        error,
        { taskId, websiteId, websiteIds, updateMethod }
      ) => {
        if (error) return [];

        let invalidateTags: TagDescription<
          'Task' | 'Attribute' | 'Filter' | 'Site'
        >[] = [
          { type: 'Site', id: 'LIST' },
          { type: 'Task', id: 'LIST' },
        ];

        // Handle websiteId (single) and websiteIds (Set)
        const websiteIdsToInvalidate = new Set<string>();
        if (websiteId) websiteIdsToInvalidate.add(websiteId);
        if (websiteIds && websiteIds instanceof Set) {
          websiteIds.forEach((id) => websiteIdsToInvalidate.add(id));
        }

        websiteIdsToInvalidate.forEach((id) => {
          invalidateTags.push({ type: 'Site', id }, { type: 'Task', id });
        });

        // Handle taskId(s)
        if (taskId) {
          if (typeof taskId === 'string' && taskId.includes(',')) {
            taskId.split(',').forEach((id) => {
              invalidateTags.push({ type: 'Attribute', id });
            });
          } else {
            invalidateTags.push({ type: 'Attribute', id: taskId });
          }
        }

        // Handle updateMethod
        if (updateMethod === 'task_tags') {
          websiteIdsToInvalidate.forEach((id) => {
            invalidateTags.push({ type: 'Filter', id });
          });
        }

        return invalidateTags;
      },
    }),
    taskUpdateStatus: build.mutation<any, UpdateStatus>({
      query: ({ taskId, value }) => {
        return {
          url: `/task/${taskId}/update-status`,
          method: 'POST',
          body: {
            value,
          },
        };
      },
      invalidatesTags: (_, error, { taskId, websiteId }) => {
        if (error) return [];

        let invalidateTags: TagDescription<
          'Task' | 'Attribute' | 'Filter' | 'Site'
        >[] = [
          { type: 'Site', id: websiteId },
          { type: 'Site', id: 'LIST' },
          { type: 'Task', id: websiteId },
          { type: 'Task', id: 'LIST' },
          ...(taskId && taskId.includes(',')
            ? taskId
                .split(',')
                .map((id) => ({ type: 'Attribute' as const, id }))
            : [{ type: 'Attribute' as const, id: taskId }]),
        ];
        return invalidateTags;
      },
    }),
    bulkTaskUpdateStatus: build.mutation<any, BulkUpdateStatus>({
      query: ({ taskIds, value }) => {
        return {
          url: `/tasks/update-status`,
          method: 'POST',
          body: {
            task_ids: taskIds,
            value,
          },
        };
      },
      invalidatesTags: (_, error, { taskIds, websiteIds }) => {
        if (error) return [];

        const invalidateTags: Array<
          TagDescription<'Task' | 'Attribute' | 'Filter' | 'Site'>
        > = [
          { type: 'Site', id: 'LIST' },
          { type: 'Task', id: 'LIST' },
          ...taskIds.map((id) => ({ type: 'Attribute' as const, id })),
          ...websiteIds.map((id) => ({ type: 'Site' as const, id })),
        ];

        return invalidateTags;
      },
    }),
    taskUpdatePriority: build.mutation<any, UpdatePriority>({
      query: ({ taskId, value }) => {
        return {
          url: `/task/${taskId}/update-priority`,
          method: 'POST',
          body: {
            value,
          },
        };
      },
      invalidatesTags: (_, error, { taskId, websiteId }) => {
        if (error) return [];

        let invalidateTags: TagDescription<
          'Task' | 'Attribute' | 'Filter' | 'Site'
        >[] = [
          { type: 'Site', id: websiteId },
          { type: 'Site', id: 'LIST' },
          { type: 'Task', id: websiteId },
          { type: 'Task', id: 'LIST' },
          ...(taskId && taskId.includes(',')
            ? taskId
                .split(',')
                .map((id) => ({ type: 'Attribute' as const, id }))
            : [{ type: 'Attribute' as const, id: taskId }]),
        ];

        return invalidateTags;
      },
    }),
    bulkTaskUpdatePriority: build.mutation<any, BulkUpdatePriority>({
      query: ({ taskIds, value }) => {
        return {
          url: `/tasks/update-priority`,
          method: 'POST',
          body: {
            task_ids: taskIds,
            value,
          },
        };
      },
      invalidatesTags: (_, error, { taskIds, websiteIds }) => {
        if (error) return [];

        const invalidateTags: Array<
          TagDescription<'Task' | 'Attribute' | 'Filter' | 'Site'>
        > = [
          { type: 'Site', id: 'LIST' },
          { type: 'Task', id: 'LIST' },
          ...taskIds.map((id) => ({ type: 'Attribute' as const, id })),
          ...websiteIds.map((id) => ({ type: 'Site' as const, id })),
        ];

        return invalidateTags;
      },
    }),
    bulkDeleteTask: build.mutation<
      any,
      { taskId: string; websiteId?: string; websiteIds?: string[] }
    >({
      query: ({ taskId }) => {
        return {
          url: '/task/bulk-delete-task',
          method: 'POST',
          body: {
            task_id: taskId,
          },
        };
      },
      invalidatesTags: (_, error, { websiteId, websiteIds, taskId }) => {
        if (error) return [];
        const invalidateTags: Array<
          TagDescription<'Task' | 'Attribute' | 'Filter' | 'Site'>
        > = [
          { type: 'Site', id: 'LIST' },
          { type: 'Task', id: 'LIST' },
          { type: 'Site', id: websiteId },
          { type: 'Task', id: websiteId },
          ...(taskId.includes(',')
            ? taskId
                .split(',')
                .map((id) => ({ type: 'Attribute' as const, id }))
            : [{ type: 'Attribute' as const, id: taskId }]),
        ];

        if (websiteIds && websiteIds.length > 0) {
          invalidateTags.push(
            ...websiteIds.map((id) => ({ type: 'Site' as const, id }))
          );
        }

        return invalidateTags;
      },
    }),
    addTaskAttachment: build.mutation<any, TaskAttachmentRequest>({
      query: ({
        task_id,
        comment_content,
        comment_type,
        complete_task,
        user_id,
        guest_author_name,
        is_note,
        guest_user_id,
        files,
      }) => {
        const formData = new FormData();
        formData.append('task_id', task_id);
        formData.append('comment_content', comment_content);
        formData.append('comment_type', comment_type);
        formData.append('complete_task', complete_task.toString());
        formData.append('user_id', user_id);
        formData.append('guest_author_name', guest_author_name);
        formData.append('is_note', is_note.toString());
        formData.append('guest_user_id', guest_user_id);

        // Convert FileList to array if necessary
        const fileArray = Array.isArray(files) ? files : Array.from(files);

        fileArray.forEach((file, index) => {
          formData.append(`files[${index}]`, file);
        });

        return {
          url: '/collaborate/comments/new',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (_, error, { task_id }) => {
        if (error) return [];
        return [{ type: 'Attribute', id: task_id }];
      },
    }),
    markTasksAsRead: build.mutation<
      any,
      { task_ids: string[]; site_id: string }
    >({
      query: ({ task_ids, site_id }) => {
        return {
          url: '/tasks/mark-as-read',
          method: 'POST',
          body: {
            task_ids,
            site_id,
          },
        };
      },
      invalidatesTags: (_, error, { task_ids, site_id }) => {
        if (error) return [];
        return [
          { type: 'Task', id: 'LIST' },
          { type: 'Task', id: site_id },
          ...task_ids.map((id) => ({ type: 'Attribute' as const, id })),
        ];
      },
    }),
    markTasksAsUnread: build.mutation<
      any,
      { task_ids: string[]; site_id: string }
    >({
      query: ({ task_ids, site_id }) => {
        return {
          url: '/tasks/mark-as-unread',
          method: 'POST',
          body: {
            task_ids,
            site_id,
          },
        };
      },
      invalidatesTags: (_, error, { task_ids, site_id }) => {
        if (error) return [];
        return [
          { type: 'Task', id: 'LIST' },
          { type: 'Task', id: site_id },
          ...task_ids.map((id) => ({ type: 'Attribute' as const, id })),
        ];
      },
    }),
    createTask: build.mutation<CreateTaskResponse, CreateTaskRequest>({
      query: (body) => ({
        url: 'collaborate/site/tasks/new',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error, { site_id }) => {
        if (error) return [];
        return [
          { type: 'Task', id: site_id },
          { type: 'Task', id: 'LIST' },
        ];
      },
    }),
    createGeneralTask: build.mutation<TaskStoreResponse, TaskStoreParams>({
      query: ({ body }) => ({
        url: '/task/store',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error, { site_id }) => {
        if (error) return [];
        return [{ type: 'Task', id: site_id }];
      },
    }),
    updateCommentContent: build.mutation<any, UpdateCommentContentRequest>({
      query: ({ task_id, ...body }) => ({
        url: '/comment/updateContent',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error, { task_id }) => {
        if (error) return [];
        return [{ type: 'Attribute', id: task_id }];
      },
    }),
    deleteComment: build.mutation<any, { task_id: string; comment_id: string }>(
      {
        query: ({ comment_id }) => ({
          url: '/comment/trash',
          method: 'POST',
          body: { comment_id },
        }),
        invalidatesTags: (_, error, { task_id }) => {
          if (error) return [];
          return [{ type: 'Attribute', id: task_id }];
        },
      }
    ),
    deleteFiles: build.mutation<
      any,
      { ids: string | number[]; task_id: string }
    >({
      query: ({ ids }) => ({
        url: '/collaborate/comments/delete-files',
        method: 'POST',
        body: { ids },
      }),
      invalidatesTags: (_, error, { task_id }) => {
        if (error) return [];
        return [{ type: 'Attribute', id: task_id }];
      },
    }),
    setEstimatedTime: build.mutation({
      queryFn: async ({ taskId, estimatedTime }, api, _, baseQuery) => {
        const workspaceId = (api.getState() as RootState).workspace
          .selectedWorkspace.id as number;
        const url = `/task/set-estimated-time/${taskId}?workspace_id=${workspaceId}`;
        const result = await baseQuery({
          url,
          method: 'POST',
          body: { estimated_time: estimatedTime },
        });

        if (result.error) {
          return { error: result.error };
        }

        return { data: result.data };
      },
      invalidatesTags: (_, error, { taskId }) => {
        if (error) return [];
        return [{ type: 'Attribute', id: taskId }];
      },
    }),
  }),
});

export const {
  useFetchSiteListQuery,
  useFetchAllTasksQuery,
  useFetchTaskListQuery,
  useFetchTaskAttributesQuery,
  useFetchAllFilterDataQuery,
  useFetchSiteFilterDataQuery,
  useFetchTaskPagesQuery,
  // Mutations
  useBulkUpdateTaskDetailsMutation,
  useTaskUpdateStatusMutation,
  useBulkTaskUpdateStatusMutation,
  useTaskUpdatePriorityMutation,
  useBulkTaskUpdatePriorityMutation,
  useBulkDeleteTaskMutation,
  useAddTaskAttachmentMutation,
  useMarkTasksAsReadMutation,
  useMarkTasksAsUnreadMutation,
  useCreateTaskMutation,
  useCreateGeneralTaskMutation,
  useSetEstimatedTimeMutation,
  useUpdateCommentContentMutation,
  useDeleteCommentMutation,
  useDeleteFilesMutation,
} = taskApi;
