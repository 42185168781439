import {
  CHANGE_ICON_SIDENAV_STATUS,
  CHANGE_SIDE_NAV_STATUS,
  CLICKUP_GET_TASK_LIST_SETTINGS,
  EMPTY_MESSAGE_SETTINGS,
  ERROR_ASANA_STORE_WORKSPACE,
  ERROR_CHANGE_CUSTOM_WEBHOOK,
  ERROR_CHANGE_INSTAWP_SETTINGS,
  ERROR_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
  ERROR_CHANGE_MAINWP_SETTINGS,
  ERROR_CHANGE_MONDAY_SETTINGS,
  ERROR_CHANGE_SLACK_SETTINGS,
  ERROR_CHANGE_WEBHOOK_STATUS,
  ERROR_CLICKUP_STORE_TEAM,
  ERROR_CLICKUP_STORE_WORKSPACE,
  ERROR_CONNECT_ROCKET_SETTING,
  ERROR_CREATE_SNIPPET,
  ERROR_CREATE_SNIPPET_TEMPLATE,
  ERROR_FETCH_ALL_SNIPPET,
  ERROR_FETCH_ALL_SNIPPET_TEMPLATE,
  ERROR_IMPORT_SNIPPET_TEMPLATE,
  ERROR_MONDAY_STORE_WORKSPACE,
  ERROR_NEW_SNIPPET_SELECTED,
  ERROR_REFRESH_WEBHOOK_TOKEN,
  ERROR_RESET_SETTINGS,
  ERROR_SAVE_GLOBAL_SETTINGS,
  ERROR_SNIPPET_DELETE,
  ERROR_UPDATE_SNIPPET,
  ERROR_VERIFY_DOMAIN,
  GLOBAL_SETTINGS_FETCHED,
  GLOBAL_SETTINGS_NOT_FETCHED,
  IMPORT_SNIPPET_TEMPLATE,
  MAKE_SIDENAV_FALSE,
  NEW_SNIPPET_SELECTED,
  RESET_SETTINGS,
  SAVE_GLOBAL_SETTINGS,
  SETTINGS_PAGE_MOUNTED,
  SETTINGS_PAGE_UNMOUNTED,
  START_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
  START_CHANGE_WEBHOOK_STATUS,
  START_CONNECT_ROCKET_SETTING,
  START_CREATE_SNIPPET,
  START_CREATE_SNIPPET_TEMPLATE,
  START_FETCH_ALL_SNIPPET,
  START_FETCH_ALL_SNIPPET_TEMPLATE,
  START_REFRESH_WEBHOOK_TOKEN,
  SUCCESS_ASANA_GET_PROJECTS_SETTINGS,
  SUCCESS_ASANA_GET_SECTION_SETTINGS,
  SUCCESS_ASANA_GET_TEAM,
  SUCCESS_ASANA_GET_WORKSPACES,
  SUCCESS_ASANA_STORE_TEAMS,
  SUCCESS_ASANA_STORE_WORKSPACE,
  SUCCESS_BASECAMP_GET_PROJECTS_SETTINGS,
  SUCCESS_BASECAMP_GET_SECTION_SETTINGS,
  SUCCESS_CHANGE_CUSTOM_WEBHOOK,
  SUCCESS_CHANGE_INSTAWP_SETTINGS,
  SUCCESS_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
  SUCCESS_CHANGE_MAINWP_SETTINGS,
  SUCCESS_CHANGE_MONDAY_SETTINGS,
  SUCCESS_CHANGE_SLACK_SETTINGS,
  SUCCESS_CHANGE_WEBHOOK_STATUS,
  SUCCESS_CLICKUP_COMPLETE_AUTH,
  SUCCESS_CLICKUP_GET_FOLDERS_SETTINGS,
  SUCCESS_CLICKUP_GET_TASK_LIST_SETTING,
  SUCCESS_CLICKUP_GET_TEAMS,
  SUCCESS_CLICKUP_GET_WORKSPACES,
  SUCCESS_CLICKUP_STORE_TEAM,
  SUCCESS_CLICKUP_STORE_WORKSPACE,
  SUCCESS_CONNECT_ROCKET_SETTING,
  SUCCESS_CREATE_SNIPPET,
  SUCCESS_CREATE_SNIPPET_TEMPLATE,
  SUCCESS_FETCH_ALL_SNIPPET,
  SUCCESS_FETCH_ALL_SNIPPET_TEMPLATE,
  SUCCESS_IMPORT_SNIPPET_TEMPLATE,
  SUCCESS_JIRA_GET_PROJECTS_SETTINGS,
  SUCCESS_JIRA_GET_WEBSITES,
  SUCCESS_JIRA_STORE_WEBSITES,
  SUCCESS_MONDAY_GET_BOARD_SETTINGS,
  SUCCESS_MONDAY_GET_GROUP_SETTINGS,
  SUCCESS_MONDAY_GET_WORKSPACE,
  SUCCESS_MONDAY_STORE_WORKSPACE,
  SUCCESS_NEW_SNIPPET_SELECTED,
  SUCCESS_REFRESH_WEBHOOK_TOKEN,
  SUCCESS_RESET_SETTINGS,
  SUCCESS_SAVE_GLOBAL_SETTINGS,
  SUCCESS_SNIPPET_DELETE,
  SUCCESS_STORE_INTEGRATION_REQUIREMENT,
  SUCCESS_TEAMWORK_GET_PROJECTS_SETTINGS,
  SUCCESS_TEAMWORK_GET_TASKLIST_SETTINGS,
  SUCCESS_TRELLO_GET_LIST_SETTINGS,
  SUCCESS_TRELLO_GET_PROJECTS_SETTINGS,
  SUCCESS_UPDATE_SNIPPET,
  SUCCESS_VALIDATE_EMAIL_INBOX,
  SUCCESS_VERIFY_DOMAIN,
  UPDATE_BASECAMP_PROJECT_SELECTED_SETTINGS,
  UPDATE_BASECAMP_TASK_SELECTED_SETTINGS,
  UPDATE_FOLDER_SELECTED_SETTINGS,
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_JIRA_PROJECT_SELECTED_SETTINGS,
  UPDATE_JIRA_WEBSITE_SELECTED_SETTINGS,
  UPDATE_MONDAY_BOARD_SELECTED_SETTINGS,
  UPDATE_MONDAY_GROUP_SELECTED_SETTINGS,
  UPDATE_MONDAY_WORKSPACE_SELECTED_SETTINGS,
  UPDATE_PROJECT_SELECTED_SETTINGS,
  UPDATE_SECTION_SELECTED_SETTINGS,
  UPDATE_TASK_LIST_SELECTED_SETTINGS,
  UPDATE_TEAMWORK_PROJECT_SELECTED_SETTINGS,
  UPDATE_TEAMWORK_TASK_SELECTED_SETTINGS,
  UPDATE_TRELLO_BOARD_SELECTED_SETTINGS,
  UPDATE_TRELLO_LIST_SELECTED_SETTINGS,
  VERIFY_DOMAIN,
} from 'actions/global_settings';

import { ERROR_VERIFY_MAILBOX, SUCCESS_VERIFY_MAILBOX } from 'actions/email';

const initState = {
  globalSettings: {},
  errorMessageGlobalSettings: '',
  wpfeedback_logo_file: null,
  isSaving: false,
  errorInSavingMessage: '',
  successInSavingMessage: '',
  isResetting: false,
  errorInResetMessage: '',
  successInResetMessage: '',
  openSideNav: false,
  openIconSideNav: false,
  refreshingWebhookToken: false,
  refreshedWebhookToken: '',
  errorInRefreshingMessage: '',
  successInRefreshingMessage: '',
  errorInChangeWebhookMessage: '',
  successInChangeWebhookMessage: '',
  successInChangeSlackSettingsMessage: '',
  errorInChangeSlackSettingskMessage: '',
  verifyDomainLoading: false,
  verifyDomainError: '',
  verifyDomainSuccess: '',
  verifyDomainData: {},
  clickupTeams: [],
  clickupWorkspaces: [],
  clickupSuccessMessage: '',
  clickupErrorMessage: '',
  asanaWorkspaces: [],
  asanaTeams: [],
  jiraWebsites: [],
  clickupFolders: [],
  clickupTaskList: [],
  asanaProjects: [],
  asanaSections: [],
  teameorkProjects: [],
  teamworkTaskLists: [],
  jiraProjects: [],
  errorInChangeMainwpSettingsMessage: '',
  rocketStatus: false,
  rocketMessage: '',
  rocketErrorMessage: '',
  basecampProjects: [],
  basecampTasks: [],
  trelloBoard: [],
  trelloList: [],
  changeToggleState: false,
  cannedSnippetList: [],
  isFetchingSnippet: false,
  errorGetSnippet: '',
  successGetSnippet: '',
  selectedSnippet: '',
  snippetIsLoading: false,
  errorSnippetMessage: '',
  snippetDetails: {},
  successUpdateSnippetMessage: '',
  errorUpdateSnippetMessage: '',
  cannedSnippetTemplateList: [],
  isFetchingSnippetTemplate: false,
  isImportSnippetTemplate: false,
  mondayWorkspace: [],
  mondayBoard: [],
  mondayGroup: [],
};

export default (previousState = initState, action) => {
  switch (action.type) {
    case SETTINGS_PAGE_MOUNTED:
    case SETTINGS_PAGE_UNMOUNTED:
      return initState;
    case GLOBAL_SETTINGS_FETCHED:
      return {
        ...previousState,
        globalSettings: action.globalSettings,
        errorMessageGlobalSettings: '',
        changeToggleState: true,
      };
    case GLOBAL_SETTINGS_NOT_FETCHED:
      return {
        ...previousState,
        globalSettings: {},
        errorMessageGlobalSettings: action.message,
        changeToggleState: false,
      };
    case UPDATE_GLOBAL_SETTINGS: {
      const { topKey, newValue } = action;

      return {
        ...previousState,
        globalSettings: {
          ...previousState.globalSettings,
          // I know it is bit inefficient but this is the only way possible without complicating the code
          [topKey]: newValue,
        },
      };
    }
    case SAVE_GLOBAL_SETTINGS:
      return {
        ...previousState,
        isSaving: true,
        errorInSavingMessage: '',
        successInSavingMessage: '',
      };
    case SUCCESS_SAVE_GLOBAL_SETTINGS:
      return {
        ...previousState,
        isSaving: false,
        errorInSavingMessage: '',
        successInSavingMessage: action.message,
      };
    case ERROR_SAVE_GLOBAL_SETTINGS:
      return {
        ...previousState,
        isSaving: false,
        errorInSavingMessage: action.message,
        successInSavingMessage: '',
      };
    case RESET_SETTINGS:
      return {
        ...previousState,
        isResetting: true,
        errorInResetMessage: '',
        successInResetMessage: '',
      };
    case SUCCESS_RESET_SETTINGS:
      return {
        ...previousState,
        isResetting: false,
        errorInResetMessage: '',
        successInResetMessage: action.message,
        globalSettings: {
          ...previousState.globalSettings,
          white_label: action.whiteLabel,
        },
      };
    case ERROR_RESET_SETTINGS:
      return {
        ...previousState,
        isResetting: false,
        errorInResetMessage: action.message,
        successInResetMessage: '',
      };
    case EMPTY_MESSAGE_SETTINGS:
      return {
        ...previousState,
        [action.key]: '',
      };
    case CHANGE_SIDE_NAV_STATUS:
      return {
        ...previousState,
        openSideNav: !previousState.openSideNav,
      };
    case MAKE_SIDENAV_FALSE:
      return {
        ...previousState,
        openSideNav: false,
      };
    case CHANGE_ICON_SIDENAV_STATUS:
      return {
        ...previousState,
        openIconSideNav: !previousState.openIconSideNav,
      };
    case START_REFRESH_WEBHOOK_TOKEN:
      return {
        ...previousState,
        refreshingWebhookToken: true,
        errorInRefreshingMessage: '',
        successInRefreshingMessage: '',
      };
    case SUCCESS_REFRESH_WEBHOOK_TOKEN:
      return {
        ...previousState,
        refreshingWebhookToken: false,
        refreshedWebhookToken: action.webhookToken,
        errorInRefreshingMessage: '',
        successInRefreshingMessage: action.message,
      };
    case ERROR_REFRESH_WEBHOOK_TOKEN:
      return {
        ...previousState,
        refreshingWebhookToken: false,
        refreshedWebhookToken: '',
        errorInRefreshingMessage: action.message,
        successInRefreshingMessage: '',
      };
    case START_CHANGE_WEBHOOK_STATUS:
      return {
        ...previousState,
        errorInChangeWebhookMessage: '',
        successInChangeWebhookMessage: '',
      };
    case SUCCESS_CHANGE_WEBHOOK_STATUS:
      return {
        ...previousState,
        errorInChangeWebhookMessage: '',
        successInChangeWebhookMessage: action.message,
      };
    case ERROR_CHANGE_WEBHOOK_STATUS:
      return {
        ...previousState,
        errorInChangeWebhookMessage: action.message,
        successInChangeWebhookMessage: '',
      };
    case SUCCESS_CHANGE_CUSTOM_WEBHOOK:
      return {
        ...previousState,
        errorInChangeWebhookMessage: '',
        successInChangeWebhookMessage: action.message,
      };
    case ERROR_CHANGE_CUSTOM_WEBHOOK:
      return {
        ...previousState,
        errorInChangeWebhookMessage: action.message,
        successInChangeWebhookMessage: '',
      };
    case SUCCESS_CHANGE_MAINWP_SETTINGS:
    case SUCCESS_CHANGE_INSTAWP_SETTINGS:
    case SUCCESS_CHANGE_SLACK_SETTINGS:
    case SUCCESS_CHANGE_MONDAY_SETTINGS:
      return {
        ...previousState,
        errorInChangeSlackSettingsMessage: '',
        successInChangeSlackSettingsMessage: action.message,
        errorInChangeMainwpSettingsMessage: '',
      };
    case ERROR_CHANGE_SLACK_SETTINGS:
      return {
        ...previousState,
        errorInChangeSlackSettingsMessage: action.message,
        successInChangeSlackSettingsMessage: '',
      };
    case ERROR_CHANGE_MAINWP_SETTINGS:
    case ERROR_CHANGE_INSTAWP_SETTINGS:
    case ERROR_CHANGE_MONDAY_SETTINGS:
      return {
        ...previousState,
        errorInChangeMainwpSettingsMessage: action.message,
        successInChangeSlackSettingsMessage: '',
        successInChangeMainwpSettingsMessage: '',
      };
    case START_CHANGE_INTEGRATION_TOGGLE_SETTINGS:
      return {
        ...previousState,
        errorInChangeSlackSettingskMessage: '',
        successInChangeSlackSettingsMessage: '',
        changeToggleState: false,
      };
    case SUCCESS_CHANGE_INTEGRATION_TOGGLE_SETTINGS:
      previousState.globalSettings.wpf_integrations = action.wpf_integrations;

      return {
        ...previousState,
        errorInChangeSlackSettingsMessage: '',
        successInChangeSlackSettingsMessage: action.message,
        globalSettings: previousState.globalSettings,
        changeToggleState: true,
      };
    case ERROR_CHANGE_INTEGRATION_TOGGLE_SETTINGS:
      return {
        ...previousState,
        errorInChangeSlackSettingskMessage: action.message,
        successInChangeSlackSettingsMessage: '',
        changeToggleState: false,
      };
    case SUCCESS_VERIFY_MAILBOX:
      return {
        ...previousState,
        successInSavingMessage: action.message,
        errorInSavingMessage: '',
      };
    case ERROR_VERIFY_MAILBOX:
      return {
        ...previousState,
        errorInSavingMessage: action.message,
        successInSavingMessage: '',
      };
    case VERIFY_DOMAIN:
      return {
        ...previousState,
        verifyDomainLoading: true,
        verifyDomainError: '',
        verifyDomainSuccess: '',
      };
    case SUCCESS_VERIFY_DOMAIN:
      return {
        ...previousState,
        verifyDomainLoading: false,
        verifyDomainError: '',
        verifyDomainSuccess: action.message,
        verifyDomainData: action.data,
        verifyDomainStatus: action.verifyDomainStatus,
      };
    case ERROR_VERIFY_DOMAIN:
      return {
        ...previousState,
        verifyDomainLoading: false,
        verifyDomainError: action.message,
        verifyDomainSuccess: '',
        verifyDomainData: action.data,
      };
    case SUCCESS_CLICKUP_COMPLETE_AUTH:
    case SUCCESS_CLICKUP_GET_TEAMS: {
      return {
        ...previousState,
        clickupTeams: action.teams,
      };
    }
    case SUCCESS_CLICKUP_STORE_TEAM: {
      const newTeams = previousState.clickupTeams.map((team) => {
        if (team.id === action.team_id) {
          return {
            ...team,
            selected: true,
          };
        } else {
          return {
            ...team,
            selected: false,
          };
        }
      });

      return {
        ...previousState,
        clickupSuccessMessage: action.message,
        clickupTeams: newTeams,
      };
    }
    case SUCCESS_CLICKUP_STORE_WORKSPACE: {
      const newSpace = previousState.clickupWorkspaces.map((team) => {
        if (team.id === action.space_id) {
          return {
            ...team,
            selected: true,
          };
        } else {
          return {
            ...team,
            selected: false,
          };
        }
      });
      return {
        ...previousState,
        clickupSuccessMessage: action.message,
        clickupWorkspaces: newSpace,
      };
    }
    case SUCCESS_ASANA_STORE_WORKSPACE: {
      const newworkspaces = previousState.asanaWorkspaces.map((workspace) => {
        if (workspace.id === action.workspace_id) {
          return {
            ...workspace,
            selected: true,
          };
        } else {
          return {
            ...workspace,
            selected: false,
          };
        }
      });
      return {
        ...previousState,
        clickupSuccessMessage: action.message,
        asanaWorkspaces: newworkspaces,
      };
    }
    case SUCCESS_JIRA_STORE_WEBSITES:
    case SUCCESS_ASANA_STORE_TEAMS: {
      const newTeams = previousState.asanaTeams.map((team) => {
        if (team.id === action.team_id) {
          return {
            ...team,
            selected: true,
          };
        } else {
          return {
            ...team,
            selected: false,
          };
        }
      });
      return {
        ...previousState,
        clickupSuccessMessage: action.message,
        asanaTeams: newTeams,
      };
    }
    case SUCCESS_STORE_INTEGRATION_REQUIREMENT: {
      return {
        ...previousState,
        clickupSuccessMessage: action.message,
      };
    }

    case ERROR_ASANA_STORE_WORKSPACE:
    case ERROR_CLICKUP_STORE_WORKSPACE:
    case ERROR_MONDAY_STORE_WORKSPACE:
    case ERROR_CLICKUP_STORE_TEAM: {
      return {
        ...previousState,
        clickupErrorMessage: action.message,
      };
    }
    case SUCCESS_CLICKUP_GET_WORKSPACES: {
      return {
        ...previousState,
        clickupWorkspaces: action.workspaces,
      };
    }
    case SUCCESS_ASANA_GET_WORKSPACES: {
      return {
        ...previousState,
        asanaWorkspaces: action.workspaces,
      };
    }
    case SUCCESS_JIRA_GET_WEBSITES: {
      return {
        ...previousState,
        jiraWebsites: action.websites,
      };
    }
    case SUCCESS_ASANA_GET_TEAM: {
      return {
        ...previousState,
        asanaTeams: action.teams,
      };
    }
    case SUCCESS_CLICKUP_GET_FOLDERS_SETTINGS:
      if (action?.folders.length > 0) {
        action?.folders.unshift({
          name: 'Select all',
          selected: false,
        });
      } else {
        action?.folders;
      }
      return {
        ...previousState,
        clickupFolders: action?.folders,
      };

    case CLICKUP_GET_TASK_LIST_SETTINGS: {
      const folders = previousState.clickupFolders.map((folder) =>
        folder.id === action.params.folder_id
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
      return {
        ...previousState,
        clickupFolders: folders,
      };
    }
    case SUCCESS_CLICKUP_GET_TASK_LIST_SETTING:
      return {
        ...previousState,
        clickupTaskList: action.taskList,
      };
    case UPDATE_TASK_LIST_SELECTED_SETTINGS: {
      const list = previousState.clickupTaskList.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        clickupTaskList: list,
      };
    }
    case UPDATE_FOLDER_SELECTED_SETTINGS: {
      const list = previousState.clickupFolders.map((task) =>
        task.id === action.folderId
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        clickupFolders: list,
      };
    }
    case SUCCESS_ASANA_GET_PROJECTS_SETTINGS:
      return {
        ...previousState,
        asanaProjects: action.Projects,
      };

    case SUCCESS_ASANA_GET_SECTION_SETTINGS:
      return {
        ...previousState,
        asanaSections: action.sections,
      };

    case UPDATE_SECTION_SELECTED_SETTINGS: {
      const list = previousState.asanaSections.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        asanaSections: list,
      };
    }
    case UPDATE_PROJECT_SELECTED_SETTINGS: {
      const list = previousState.asanaProjects.map((task) =>
        task.id === action.projectId
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        asanaProjects: list,
      };
    }
    case SUCCESS_TEAMWORK_GET_PROJECTS_SETTINGS:
      return {
        ...previousState,
        teameorkProjects: action.Projects,
      };

    case SUCCESS_TEAMWORK_GET_TASKLIST_SETTINGS:
      return {
        ...previousState,
        teamworkTaskLists: action.taskLists,
      };

    case UPDATE_TEAMWORK_PROJECT_SELECTED_SETTINGS: {
      const list = previousState.teameorkProjects.map((task) =>
        task.id === action.projectId
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        teameorkProjects: list,
      };
    }
    case UPDATE_TEAMWORK_TASK_SELECTED_SETTINGS: {
      const list = previousState.teamworkTaskLists.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        teamworkTaskLists: list,
      };
    }
    case SUCCESS_JIRA_GET_PROJECTS_SETTINGS:
      return {
        ...previousState,
        jiraProjects: action.Projects,
      };
    case UPDATE_JIRA_PROJECT_SELECTED_SETTINGS: {
      const list = previousState.jiraProjects.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        jiraProjects: list,
      };
    }
    case UPDATE_JIRA_WEBSITE_SELECTED_SETTINGS: {
      const list = previousState.jiraWebsites.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        jiraWebsites: list,
      };
    }
    case START_CONNECT_ROCKET_SETTING: {
      return {
        ...previousState,
        rocketStatus: false,
        rocketMessage: '',
        rocketErrorMessage: '',
      };
    }
    case SUCCESS_CONNECT_ROCKET_SETTING: {
      return {
        ...previousState,
        rocketStatus: action.status,
        rocketMessage: action.message,
      };
    }
    case ERROR_CONNECT_ROCKET_SETTING: {
      return {
        ...previousState,
        rocketErrorMessage: action.message,
      };
    }
    case SUCCESS_BASECAMP_GET_PROJECTS_SETTINGS:
      return {
        ...previousState,
        basecampProjects: action.Projects,
      };
    case SUCCESS_BASECAMP_GET_SECTION_SETTINGS:
      return {
        ...previousState,
        basecampTasks: action.task,
      };
    case UPDATE_BASECAMP_PROJECT_SELECTED_SETTINGS: {
      const list = previousState.basecampProjects.map((task) =>
        Number(task.id) === Number(action.projectId)
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );

      return {
        ...previousState,
        basecampProjects: list,
      };
    }
    case UPDATE_BASECAMP_TASK_SELECTED_SETTINGS: {
      const list = previousState.basecampTasks.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        basecampTasks: list,
      };
    }
    case SUCCESS_TRELLO_GET_PROJECTS_SETTINGS:
      return {
        ...previousState,
        trelloBoard: action.Projects,
      };
    case SUCCESS_TRELLO_GET_LIST_SETTINGS:
      return {
        ...previousState,
        trelloList: action.sections,
      };
    case UPDATE_TRELLO_BOARD_SELECTED_SETTINGS: {
      const list = previousState.trelloBoard.map((task) =>
        task.id === action.projectId
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        trelloBoard: list,
      };
    }
    case UPDATE_TRELLO_LIST_SELECTED_SETTINGS: {
      const list = previousState.trelloList.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        trelloList: list,
      };
    }
    case START_FETCH_ALL_SNIPPET:
      return {
        ...previousState,
        cannedSnippetList: [],
        isFetchingSnippet: true,
        errorGetSnippet: '',
        successGetSnippet: '',
      };
    case SUCCESS_FETCH_ALL_SNIPPET:
      return {
        ...previousState,
        cannedSnippetList: action.data,
        isFetchingSnippet: false,
        errorGetSnippet: '',
        snippetDetails: {},
        // successGetSnippet: action.message,
      };
    case ERROR_FETCH_ALL_SNIPPET:
      return {
        ...previousState,
        isFetchingSnippet: false,
        errorGetSnippet: action.message,
        successGetSnippet: '',
      };
    case START_CREATE_SNIPPET:
      return {
        ...previousState,
        isCreatingSnippet: true,
        errorGetSnippet: '',
        successGetSnippet: '',
      };
    case SUCCESS_CREATE_SNIPPET:
      // const newSnippet = previousState.cannedSnippetList.concat(action.data)
      previousState.cannedSnippetList.unshift(action.data);

      return {
        ...previousState,
        cannedSnippetList: previousState.cannedSnippetList,
        isCreatingSnippet: false,
        errorGetSnippet: '',
        successGetSnippet: action.message,
      };
    case ERROR_CREATE_SNIPPET:
      return {
        ...previousState,
        isCreatingSnippet: false,
        errorGetSnippet: action.message,
        successGetSnippet: '',
      };
    case NEW_SNIPPET_SELECTED:
      return {
        ...previousState,
        selectedSnippet: action.snippetId,
        snippetIsLoading: true,
        errorSnippetMessage: '',
        snippetDetails: {},
      };
    case 'DESELECT_SNIPPET':
      return {
        ...previousState,
        selectedSnippet: '',
      };
    case SUCCESS_NEW_SNIPPET_SELECTED:
      return {
        ...previousState,
        snippetIsLoading: false,
        errorSnippetMessage: '',
        snippetDetails: action.data,
      };
    case ERROR_NEW_SNIPPET_SELECTED:
      return {
        ...previousState,
        snippetIsLoading: false,
        errorSnippetMessage: action.message,
        snippetDetails: {},
      };
    case SUCCESS_SNIPPET_DELETE:
      return {
        ...previousState,
        // snippetIsLoading: false,
        successSnippetMessage: action.message,
        errorSnippetMessage: '',
      };
    case ERROR_SNIPPET_DELETE:
      return {
        ...previousState,
        // snippetIsLoading: false,
        successSnippetMessage: '',
        errorSnippetMessage: action.message,
      };
    case SUCCESS_UPDATE_SNIPPET:
      let newCannedList = previousState.cannedSnippetList.map((each) =>
        each.id === action.data.id
          ? { ...each, title: action.data.title }
          : each
      );
      let newSnippetDetails = {
        ...previousState.snippetDetails,
        title: action.data.title
          ? action.data.title
          : previousState.snippetDetails.title,
        content: action.data.content
          ? action.data.content
          : previousState.snippetDetails.content,
        updated_at: action.data.updated_at,
      };
      return {
        ...previousState,
        cannedSnippetList: newCannedList,
        snippetDetails: newSnippetDetails,
        // snippetIsLoading: false,
        successUpdateSnippetMessage: action.message,
        errorUpdateSnippetMessage: '',
      };
    case ERROR_UPDATE_SNIPPET:
      return {
        ...previousState,
        // snippetIsLoading: false,
        successUpdateSnippetMessage: '',
        errorUpdateSnippetMessage: action.message,
      };
    case START_CREATE_SNIPPET_TEMPLATE:
      return {
        ...previousState,
        isCreatingSnippet: true,
        errorGetSnippet: '',
        successGetSnippet: '',
      };
    case SUCCESS_CREATE_SNIPPET_TEMPLATE:
      const newSnippetTemplate = previousState.cannedSnippetTemplateList.concat(
        action.data
      );
      // previousState.cannedSnippetTemplateList.unshift(action.data)

      return {
        ...previousState,
        cannedSnippetTemplateList: newSnippetTemplate,
        isCreatingSnippet: false,
        errorGetSnippet: '',
        successGetSnippet: action.message,
      };
    case ERROR_CREATE_SNIPPET_TEMPLATE:
      return {
        ...previousState,
        isCreatingSnippet: false,
        errorGetSnippet: action.message,
        successGetSnippet: '',
      };
    case START_FETCH_ALL_SNIPPET_TEMPLATE:
      return {
        ...previousState,
        cannedSnippetTemplateList: [],
        isFetchingSnippetTemplate: true,
        errorGetSnippet: '',
        successGetSnippet: '',
      };
    case SUCCESS_FETCH_ALL_SNIPPET_TEMPLATE:
      return {
        ...previousState,
        cannedSnippetTemplateList: action.data,
        isFetchingSnippetTemplate: false,
        errorGetSnippet: '',
        // successGetSnippet: action.message,
      };
    case ERROR_FETCH_ALL_SNIPPET_TEMPLATE:
      return {
        ...previousState,
        isFetchingSnippetTemplate: false,
        errorGetSnippet: action.message,
        successGetSnippet: '',
      };
    case IMPORT_SNIPPET_TEMPLATE:
      return {
        ...previousState,
        isImportSnippetTemplate: true,
        errorGetSnippet: '',
        successGetSnippet: '',
      };
    case SUCCESS_IMPORT_SNIPPET_TEMPLATE:
      return {
        ...previousState,
        isImportSnippetTemplate: false,
        errorGetSnippet: '',
        successGetSnippet: action.message,
      };
    case ERROR_IMPORT_SNIPPET_TEMPLATE:
      return {
        ...previousState,
        isImportSnippetTemplate: false,
        errorGetSnippet: action.message,
        successGetSnippet: '',
      };
    case SUCCESS_MONDAY_GET_WORKSPACE: {
      return {
        ...previousState,
        mondayWorkspace: action.workspace,
      };
    }
    case UPDATE_MONDAY_WORKSPACE_SELECTED_SETTINGS: {
      const list = previousState.mondayWorkspace.map((space) =>
        space.id === action.id
          ? {
              ...space,
              selected: true,
            }
          : space.selected === true
            ? {
                ...space,
                selected: false,
              }
            : space
      );
      return {
        ...previousState,
        mondayWorkspace: list,
      };
    }
    case SUCCESS_MONDAY_STORE_WORKSPACE: {
      return {
        ...previousState,
        clickupSuccessMessage: action.message,
      };
    }
    case SUCCESS_MONDAY_GET_BOARD_SETTINGS:
      return {
        ...previousState,
        mondayBoard: action.board,
      };
    case SUCCESS_MONDAY_GET_GROUP_SETTINGS:
      return {
        ...previousState,
        mondayGroup: action.group,
      };
    case UPDATE_MONDAY_BOARD_SELECTED_SETTINGS: {
      const list = previousState.mondayBoard.map((task) =>
        task.id === action.projectId
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        mondayBoard: list,
      };
    }
    case UPDATE_MONDAY_GROUP_SELECTED_SETTINGS: {
      const list = previousState.mondayGroup.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        mondayGroup: list,
      };
    }
    case SUCCESS_VALIDATE_EMAIL_INBOX:
      return {
        ...previousState,
        globalSettings: {
          ...previousState?.globalSettings,
          mailbox_setting: {
            ...previousState?.globalSettings?.mailbox_setting,
            forward_email: action?.data,
          },
        },
      };
    default:
      return previousState;
  }
};
