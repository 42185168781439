import { useAppDispatch, useAppSelector } from "@src/hooks/redux";
import { HANDLE_PLANS_DIALOG } from "actions/user";
import { motion } from "framer-motion";
import { CalendarDays, CircleArrowUp } from "lucide-react";
import { Button, Progress } from "ui";
import { dayLeftForTrial, dayUsedForTrial } from "utils/functions";

const TrialStatus = () => {
    const {
        user,
        userTrialData,
    } = useAppSelector((state: any) => ({
        user: state.user.userData,
        userTrialData: state.user.userData.trial,
    }));

    const dispatch = useAppDispatch();
    const trialActive = userTrialData?.activation_date && dayLeftForTrial(userTrialData?.activation_date) <= 14 && user?.role === 'admin' && user?.trial?.status === 'active';
    const trialEndedUpgrade = userTrialData.activation_date && dayLeftForTrial(userTrialData.activation_date) >= 14 && dayLeftForTrial(userTrialData.activation_date) <= 17 && user.role === 'admin' && user.trial.status === 'expired';
    const handleNavigateToUpgrade = () => {
        if (user.plan.from_free_plan && user.subscription) {
            dispatch({ type: HANDLE_PLANS_DIALOG, params: true });
        } else {
            const upgradeUrl =
                user.plan.upsell_url ||
                `${import.meta.env.VITE_APP_MAIN_WEBSITE_URL}/upgrade?_from=${user.source}`;
            window.open(upgradeUrl);
        }
    };

    return (<>
        {(trialActive || trialEndedUpgrade) && (
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 100 }}
                transition={{
                    delay: 0.2,
                    duration: 0.25,
                    type: 'spring',
                    stiffness: 240,
                    damping: 15,
                }}
                className="w-full bg-tertiary/10 rounded-xl p-3 mt-2 gap-2 flex flex-col text-secondary outline outline-tertiary/50 " >
                <div className="flex flex-row justify-between items-center">
                    <div className="text-lg font-bold">
                        {trialEndedUpgrade && (
                            <>Free Plan</>
                        )}
                        {trialActive &&
                            <>Pro Plan</>
                        }
                    </div>
                    <div className="bg-tertiary text-white rounded-md leading-none flex items-center h-fit py-1 px-2 gap-1">
                        <CalendarDays size={14} />
                        {/* Show this text when trial has ended */}
                        {trialEndedUpgrade && (
                            <>Trial Ended</>
                        )}
                        {/* Show this text when trial is active */}
                        {trialActive &&
                            <>Trial</>
                        }</div>
                </div>
                <div className="flex flex-col gap-2">
                    <span className="text-xs font-medium leading-none">
                        {/* Show this text when trial has ended */}

                        {/* Show this text when trial is active */}
                        {trialActive ? (
                            <>Free trial time period</>
                        ) : (
                            <>Your free 14 day trial has ended.</>
                        )
                        }
                    </span>
                    <span>
                        <Progress className="h-2" size="sm" variant="secondary" progress={Math.min(dayUsedForTrial(userTrialData?.activation_date) / 14 * 100, 100)} />
                        {trialEndedUpgrade && (
                            <span className="text-xs text-tertiary font-medium leading-none">14 day free trial complete</span>
                        )}
                        {trialActive &&
                            <span className="text-xs text-tertiary font-medium leading-none">On day {dayUsedForTrial(userTrialData?.activation_date)} of 14</span>
                        }
                    </span>
                </div>
                <Button variant="outline" size="sm" className="text-sm" leftIcon={<CircleArrowUp size={18} />} onClick={handleNavigateToUpgrade}>Upgrade Your Account</Button>
            </motion.div>
        )}
    </>
    );
}
export default TrialStatus;