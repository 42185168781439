// translations/i18n.ts
import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, { LocizeBackendOptions } from 'i18next-locize-backend';
import { locizeEditorPlugin } from 'locize';
import { initReactI18next } from 'react-i18next';

interface I18nInitOptions {
  projectId: string;
  apiKey: string;
  isProduction: boolean;
  defaultNS?: string;
  debugLocize?: boolean;
}

const initializeI18n = ({
  projectId,
  apiKey,
  isProduction,
  defaultNS = 'common',
  debugLocize = false,
}: I18nInitOptions): typeof i18n => {
  const initOptions: InitOptions & { backend: LocizeBackendOptions } = {
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr-CA'],
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    debug: !isProduction,
    backend: {
      projectId,
      apiKey,
      referenceLng: 'en',
      allowedAddOrUpdateHosts: [
        '7e7de9f2-8e75-4979-afff-82ded4bfd6f7.localhost',
      ],
    },
    saveMissing: !isProduction,
    saveMissingTo: 'all',
    updateMissing: !isProduction,
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
    },

    defaultNS,
    ns: [
      'common',
      'projects',
      'boards',
      'inbox',
      'settings',
      'auth',
      'errors',
      'forms',
      'tooltips',
      'notifications',
      'collaborate',
      'time_entries',
      'workflows',
    ],
  };

  // Initialize i18next with the required plugins
  const i18nInstance = i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector);

  // Conditionally add the locize plugin
  if (!isProduction && debugLocize) {
    i18nInstance.use(
      locizeEditorPlugin({
        show: true,
      })
    );
  }

  // Initialize with options
  i18nInstance.init(initOptions);

  return i18n;
};

export { initializeI18n };
